import React, { useEffect } from "react";
import GuestForm from "./components/Guest/GuestForm";
import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { service } from "./network/service"

function App({ signOut }) {

  useEffect(() => {
    userLoginTrack()
  }, [])

  const userLoginTrack = async () => {
    const userLog = localStorage.getItem('userLog');
    const userInfo = await Auth.currentAuthenticatedUser();
    const userEmail = userInfo.attributes.email;
    if (!userLog && userEmail) {
      localStorage.setItem('userLog', 'true');
      service.userActivityLog({
        action: 'Login',
        api_request_data: {}
      })

    }
  }



  return (
    <div className="App">
      <GuestForm signOut={signOut} />
    </div>
  );
}

export default withAuthenticator(App);
