let EVENTS_URL = "https://i44jqrheff2opeexme44luqxhi0gbjmb.lambda-url.us-west-1.on.aws/events"
let SESSIONS_URL = "https://i44jqrheff2opeexme44luqxhi0gbjmb.lambda-url.us-west-1.on.aws"
let TICKET_TYPE_URL = "https://i44jqrheff2opeexme44luqxhi0gbjmb.lambda-url.us-west-1.on.aws"
let CSV_UPLOAD_URL = "https://i44jqrheff2opeexme44luqxhi0gbjmb.lambda-url.us-west-1.on.aws/csvupload"
let CSV_UPLOAD_STATUS = "https://i44jqrheff2opeexme44luqxhi0gbjmb.lambda-url.us-west-1.on.aws/csvupload/status"
let INDIVIDUAL_GUEST_URL = "https://i44jqrheff2opeexme44luqxhi0gbjmb.lambda-url.us-west-1.on.aws/individual"
// let EVENTS_URL = "http://localhost:3001/events"
// let SESSIONS_URL = "http://localhost:3001"
// let TICKET_TYPE_URL = "http://localhost:3001"
// let CSV_UPLOAD_URL = "http://localhost:3001/csvupload"
// let CSV_UPLOAD_STATUS = "http://localhost:3001/csvupload/status"
// let INDIVIDUAL_GUEST_URL = "http://localhost:3001/individual"
let USER_ACTIVITY_LOG_API_URL = "https://jfflfhwvzfuqxeu32j3mm66pki0ytycf.lambda-url.us-east-1.on.aws/log/add"
let TICKETING_API_URL = "ticketing.area15.com"
let TICKETING_API_OAUTH_URL = "https://api.ticketure.com"
let TOKEN = `Basic YXJlYTE1LWVjb21tOmhOVE5vYkNyVUtWN1pCREppdm9J`
let CUSTOMER_NOTES = '[{"value": "", "label": "GA"}, {"value": "MEDIA", "label": "MEDIA"}, {"value": "WORKING", "label": "WORKING"}, {"value": "VIP", "label": "VIP"}, {"value": "BACKSTAGE / ARTIST GUEST", "label": "BACKSTAGE / ARTIST GUEST"}, {"value": "ARTIST", "label": "ARTIST"}, {"value": "INFLUENCER", "label": "INFLUENCER"}]';

module.exports = {
    EVENTS_URL,
    SESSIONS_URL,
    TICKET_TYPE_URL,
    TICKETING_API_URL,
    TICKETING_API_OAUTH_URL,
    TOKEN,
    CSV_UPLOAD_URL,
    INDIVIDUAL_GUEST_URL,
    CSV_UPLOAD_STATUS,
    USER_ACTIVITY_LOG_API_URL,
    CUSTOMER_NOTES
}