import React from 'react';
import { render } from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Amplify, Auth } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

// Configure the Okta tokens in Amplify
Auth.configure({
  oauth: {
    domain: config.oauth.domain,
    scope: config.oauth.scope,
    redirectSignIn: config.oauth.redirectSignIn,
    redirectSignOut: config.oauth.redirectSignOut,
    responseType: config.oauth.responseType,
  },
});

// Get the URL and remove the leading '#' character
const url = window.location.href;
const paramsString = url.substr(url.indexOf('#') + 1);

// Parse the URL parameters into an object
const urlParams = new URLSearchParams(paramsString);

// Extract the desired values from the URL parameters
const idToken = urlParams.get('id_token');
const expiresIn = urlParams.get('expires_in');

const signInWithOktaTokens = async () => {
  const providerName = `cognito-idp.us-east-1.amazonaws.com/${config.aws_user_pools_id}`;
  Auth.federatedSignIn(
    providerName,
    {
      token: idToken,
      expires_at: expiresIn,
    }
  )
    .then((cred) => {
      console.log('User signed in successfully');
      return Auth.currentAuthenticatedUser();

    })
    .then((user) => {
      window.location.href = '/'
    })
    .catch((error) => {
      console.error('Error signing in:', error);
    });
};

idToken && signInWithOktaTokens();

const root = document.getElementById('root');
render(<App />, root);
