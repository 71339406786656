import React, { useState, useEffect, Fragment } from "react";
import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse';
import { service } from "../../network/service";
import Select from "react-select";
import { Form, Input, Label } from "reactstrap";
import { CSVLink } from "react-csv";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "../style/guestStyle.css";

function GuestForm(props) {
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedSession, setSelectedSession] = useState();
  const [selectedTicketType, setSelectedTicketType] = useState();
  const [selectedCustomerNotes, setSelectedCustomerNotes] = useState();
  const [customerNotes, setCustomerNotes] = useState([]);
  const [events, setEvents] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [values, setValues] = useState({ noOfTickets: 1 });
  const [errors, setErrors] = useState({});
  const [buttonContent, setButtonContent] = useState("Submit");
  const [csvFile, setCsvFile] = useState();
  const [csvFilename, setCsvFileName] = useState("");
  const [guestData, setGuestData] = useState([]);
  const [guestColumns, setGuestColumns] = useState([]);
  const [guestFields, setGuestFields] = useState([]);
  const [csvProcessingCompleted, setCsvProcessingCompleted] = useState(false);
  const [parsedCsvValues, setParsedCsvValues] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [toggleType, setToggleType] = useState('individual')
  const [jobId, setJobId] = useState('')

  //To fetch customerNotes
  useEffect(() => {
    setCustomerNotes(service.getCustomerNotes());
  }, []);

  //To fetch events
  useEffect(() => {
    service.getEvents().then((data) => {
      let options = data
        .map((element) => {
          return {
            value: element.id,
            label: element.name,
          };
        })
        .sort((a, b) => {
          const labelA = a.label.toUpperCase();
          const labelB = b.label.toUpperCase();

          if (labelA < labelB) {
            return -1;
          }

          if (labelA > labelB) {
            return 1;
          }

          return 0;
        });

      setEvents(options);
    });
  }, []);

  useEffect(() => {

    if (jobId) {
      // Fetch progress initially
      fetchProgress();

      // Setup interval to fetch progress every 5 seconds
      const intervalId = setInterval(fetchProgress, 5000);

      // Cleanup the interval when the component unmounts
      return () => clearInterval(intervalId);
    }

  }, [jobId]);

  //To fetch sessions at every event change
  useEffect(() => {
    if (selectedEvent) {
      service.getSessions(selectedEvent.value).then((data) => {
        let options = data.map((element) => {
          return {
            value: element.id,
            label: new Date(element["start_datetime"]).toLocaleString("en-US", {
              timeZone: "America/Los_Angeles",
            }),
          };
        });
        setSessions(options);
      });
    }
  }, [selectedEvent]);

  //To get ticket types at every session change
  useEffect(() => {
    if (selectedSession) {
      service.getTicketTypes(selectedSession.value).then((data) => {
        let options = [];
        // filter out all Ticket Types that don't have a Ticket Group with the name "Guest List"
        options = data.ticketType.filter((item) => data.ticketGroup[`${item.ticket_group_id}`] === 'Guest List').map((element) => {
          const ticketGroupId = element.ticket_group_id;
          return {
            value: element.id,
            label: `${data.ticketGroup[`${ticketGroupId}`]} - ${element.name}`,
          };
        });
        setTicketTypes(options);
      });
    }
  }, [selectedSession]);

  // Table columns after csv processing
  useEffect(() => {
    if (parsedCsvValues.length > 0) {
      const columnTitles = guestColumns.map((item) => {
        return {
          dataField: item,
          text: item.replace(/_/i, " ").toUpperCase(),
          filter: textFilter(),
          classes: "border-0 align-middle",
          headerClasses: "border-0",
          sort: true,
        };
      });
      setGuestFields([...columnTitles]);
      setCsvProcessingCompleted(true);
    } else {
      setGuestData([]);
    }
  }, [parsedCsvValues]);

  //Function to handle change of event
  const handleEvent = (event) => {
    setSelectedEvent(event);
    setSelectedSession("");
    setSelectedTicketType("");
    if (errors.event) {
      delete errors.event;
      setErrors({ ...errors });
    }
  };

  //Function to handle change of session
  const handleSession = (session) => {
    setSelectedSession(session);
    setSelectedTicketType("");
    if (errors.session) {
      delete errors.session;
      setErrors({ ...errors });
    }
  };

  //Function to handle change of ticket type
  const handleTicketType = (tickettype) => {
    setSelectedTicketType(tickettype);
    if (errors.ticketType) {
      delete errors.ticketType;
      setErrors({ ...errors });
    }
  };

  const handleCustomerNotes = (CustomerNote) => {
    setSelectedCustomerNotes(CustomerNote);
    if (errors.customerNote) {
      delete errors.customerNote;
      setErrors({ ...errors });
    }
  }

  //Function to handle change of promo code
  const handlePromoCode = (promocode) => {
    setPromoCode(promocode);
    if (errors.promoCode) {
      delete errors.promoCode;
      setErrors({ ...errors });
    }
  };

  //Function to handle change of CSV file upload
  const handleFileUpload = (event) => {
    if (event.target.files[0]) {
      setCsvFile(event.target.files[0]);
      setCsvFileName(event.target.files[0].name);
    }
    if (errors.csvFile) {
      delete errors.csvFile;
      setErrors({ ...errors });
    }
  };

  const fetchProgress = async () => {
    const progressData = await service.getCsvUploadProgress(jobId);
    progressData.status && setButtonContent(progressData.progress)
    if (progressData.status === 'COMPLETED') {
      setJobId('')
    }
  };

  //submit functionality
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formValidation()) {
      setButtonContent('Processing')
      let uniqueJobId = uuidv4()
      setJobId(uniqueJobId);
      let lambdaResponse = [];
      if (toggleType === 'spreadsheet') {
        lambdaResponse = await service.sendCSVToLambda(csvFile, selectedEvent.value, selectedSession.value, selectedTicketType.value, selectedCustomerNotes.value, promoCode, uniqueJobId)
      } else {
        const { firstname, lastname, email, noOfTickets } = values
        const individualData = {
          eventId: selectedEvent.value,
          sessionId: selectedSession.value,
          ticketTypeId: selectedTicketType.value,
          customerNote: selectedCustomerNotes.value,
          promoCode: promoCode,
          firstName: firstname,
          lastName: lastname,
          email,
          quantity: noOfTickets
        }
        lambdaResponse = await service.createInduvidualGuestEntry(individualData);
      }
      let csvColumns = [], csvValues = [];

      const excludedColumns = ['eventId', 'sessionId', 'ticketTypeId', 'promoCode', 'Event ID', 'Session ID', 'Ticket Type ID', 'Promo Code'];
      // Exclude specified columns from lambdaResponse and set to guestData
      const modifiedData = lambdaResponse.map((item) => {
        const modifiedItem = { ...item };
        excludedColumns.forEach((column) => {
          delete modifiedItem[column];
        });
        return modifiedItem;
      });

      modifiedData.map((row) => {
        row.Processed = row.Processed.toLowerCase()
        csvColumns.push(Object.keys(row));
        csvValues.push(Object.values(row));
      });

      setGuestData(modifiedData);
      setGuestColumns(csvColumns[0]);
      setParsedCsvValues(csvValues);

      setExportData([csvColumns[0].map((item) => {
        return item.toUpperCase()
      }), ...csvValues]);
    }
  };

  //Function to validate the form
  const formValidation = () => {
    let formIsvalid = true;
    let formErrors = {};

    if (!selectedEvent) {
      formIsvalid = false;
      formErrors.event = "Event is required!";
    }
    if (!selectedSession) {
      formIsvalid = false;
      formErrors.session = "Session is required!";
    }
    if (!selectedTicketType) {
      formIsvalid = false;
      formErrors.ticketType = "Ticket type is required!";
    }

    if (!promoCode || promoCode.trim().length === 0) {
      formIsvalid = false;
      formErrors.promoCode = `Department Code is required!`;
    }
    if (toggleType === 'individual') {
      if (!values.firstname || values.firstname.trim().length === 0) {
        formIsvalid = false;
        formErrors.firstname = "First Name is required!";
      }
      if (!values.lastname || values.lastname.trim().length === 0) {
        formIsvalid = false;
        formErrors.lastname = "Last Name is required!";
      }
      if (!values.email || values.email.trim().length === 0) {
        formIsvalid = false;
        formErrors.email = "Email is required!";
      }
      if (validateEmail(values.email) !== true) {
        formIsvalid = false;
        formErrors.email = "Enter a valid Email!";
      }
      if (values.noOfTickets < 1) {
        formIsvalid = false;
        formErrors.noOfTickets = "At least one ticket is required!";
      }
      if (!values.noOfTickets) {
        formIsvalid = false;
        formErrors.noOfTickets = "Number of Tickets is required!";
      }

    } else {
      if (!csvFile) {
        formIsvalid = false;
        formErrors.csvFile = "CSV file is required!";
      }
    }

    setErrors(formErrors);
    return formIsvalid;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const generateCsvData = () => {
    const data = [
      {
        "First Name": "",
        "Last Name": "",
        "Email": "",
        "Quantity": ""
      }
    ];

    return data;
  };

  const handleDownload = () => {
    const csvData = generateCsvData();

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'template.csv';
    link.click();
  };

  //Function to reset form
  const handleFormReset = (event) => {
    event.preventDefault();
    setCsvFile("");
    setButtonContent("Submit");
    setSelectedEvent("");
    setSelectedSession("");
    setPromoCode("");
    setSelectedTicketType();
    setCsvProcessingCompleted(false);
    setCsvFileName("");
    setValues({ noOfTickets: 1 })
  };

  //Function for custom pagination total renderer
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  //Custom size per page list in bootstrap table
  let customSizeperPageList = [{ text: "10", value: 10 }];
  for (let i = 10; i < guestData.length; i++) {
    if (i % 100 === 0) {
      customSizeperPageList = [
        ...customSizeperPageList,
        { text: `${i}`, value: i },
      ];
    }
  }

  // bootstrap table options
  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: customSizeperPageList,
  };

  const handleLogout = async () => {
    service.userActivityLog({
      action: 'Logout',
      api_request_data: {}
    }).then((data) => {
      localStorage.removeItem('userLog')
      props.signOut()
    })

  }


  return (
    <Fragment>
      {csvProcessingCompleted ? (
        <Fragment>
          {guestData ? (
            <Fragment>
              <div className="logout-btn-wrapper">
                <button
                  className="back-btn"
                  onClick={(event) => {
                    handleFormReset(event);
                  }}
                >
                  Return to Guest List
                </button>
                {exportData && (
                  <CSVLink
                    data={exportData}
                    filename={"guest-data.csv"}
                    className="export-csv"
                  >
                    Export
                  </CSVLink>
                )}

                <button
                  onClick={() => { handleLogout() }}
                >
                  Logout
                </button>
              </div>
              <div className="table-wrapper">
                <div className="table-responsive">
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={guestData.map((item, index) => {
                      return { ...item, id: index + Date.now() };
                    })}
                    columns={guestFields}
                    bordered={true}
                    condensed={true}
                    hover={true}
                    tabIndexCell={true}
                    striped={true}
                    expanded={true}
                    expandable={true}
                    searchable={true}
                    filter={filterFactory({})}
                    classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                    rowClasses="btn-reveal-trigger border-top border-200"
                    headerClasses="bg-200 text-900 border-y border-200 bootstrap-table-header"
                    pagination={paginationFactory(options)}
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            !guestData && <div className="exception-msg">No datas found</div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <div className="guest-form-wrapper">
            <div className="logout-btn-wrapper logout" >
              <button
                onClick={() => { handleLogout() }}
              >
                Logout
              </button>
            </div>
            <div className="inner-wrapper col-sm-10 offset-sm-1 col-12">

              <div className="form-content">
                <div className="form-items ">
                  <h3>Guest List</h3>
                  <Form
                    onSubmit={(event) => { handleSubmit(event) }}
                    className="form-container"
                  >
                    <div className="col-md-12 mb-1 mt-3">
                      <Label htmlFor="event">Choose Event </Label>
                      <Select
                        value={selectedEvent}
                        name="event"
                        onChange={(value) => {
                          handleEvent(value);
                        }}
                        classNameNamePrefix="react-select"
                        options={events}
                      />
                      {errors.event && (
                        <p className="text-danger">{errors.event}</p>
                      )}
                    </div>

                    <div className="col-md-12 mb-1">
                      <Label htmlFor="session">Choose Session </Label>
                      <Select
                        value={selectedSession}
                        name="session"
                        onChange={(value) => {
                          handleSession(value);
                        }}
                        classNameNamePrefix="react-select"
                        options={sessions}
                      />
                      {errors.session && (
                        <p className="text-danger">{errors.session}</p>
                      )}
                    </div>

                    <div className="col-md-12 mb-1">
                      <Label htmlFor="ticketype">Choose Ticket type </Label>
                      <Select
                        value={selectedTicketType}
                        name="ticketype"
                        onChange={(value) => {
                          handleTicketType(value);
                        }}
                        classNameNamePrefix="react-select"
                        options={ticketTypes}
                      />
                      {errors.ticketType && (
                        <p className="text-danger">{errors.ticketType}</p>
                      )}
                    </div>
                    <div className="col-md-12 mb-1">
                      <Label htmlFor="credentials">Choose Credentials </Label>
                      <Select
                        value={selectedCustomerNotes}
                        name="customerNotes"
                        onChange={(value) => {
                          handleCustomerNotes(value);
                        }}
                        classNameNamePrefix="react-select"
                        options={customerNotes}
                      />
                      {errors.customerNotes && (
                        <p className="text-danger">{errors.customerNotes}</p>
                      )}
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="toggle-btn-wrapper">
                        <button type="button" className={toggleType === 'individual' && 'active'} onClick={() => {
                          setToggleType('individual')
                        }} >
                          Individual
                        </button>
                        <button type="button" className={toggleType === 'spreadsheet' && 'active'} onClick={() => {
                          setToggleType('spreadsheet')
                        }} >
                          Spreadsheet
                        </button>

                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      {toggleType === 'spreadsheet' && <span className="download" onClick={handleDownload}>Download CSV Template</span>}
                    </div>
                    {toggleType === 'spreadsheet' ? <div className="col-md-12 mb-1 d-flex flex-column">
                      <Label htmlFor="csvFile">Upload CSV </Label>

                      <input
                        id="csvFile"
                        className="ml-3"
                        type="file"
                        accept=".csv"
                        onChange={(file) => handleFileUpload(file)}
                      />
                      <p>{csvFilename}</p>
                      {errors.csvFile && (
                        <p className="text-danger">{errors.csvFile}</p>
                      )}
                    </div> :
                      <div className="individual-wrapper">
                        <div className="col-md-12 mb-1 d-flex flex-column">
                          <Label htmlFor="csvFile">First Name </Label>
                          <Input
                            value={values.firstname}
                            name="firstname"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          />
                          {errors.firstname && (
                            <p className="text-danger">{errors.firstname}</p>
                          )}
                        </div>
                        <div className="col-md-12 mb-1 d-flex flex-column">
                          <Label htmlFor="csvFile">Last Name </Label>
                          <Input
                            value={values.lastname}
                            name="lastname"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          />
                          {errors.lastname && (
                            <p className="text-danger">{errors.lastname}</p>
                          )}
                        </div>
                        <div className="col-md-12 mb-1 d-flex flex-column">
                          <Label htmlFor="csvFile">Email </Label>
                          <Input
                            value={values.email}
                            name="email"
                            onChange={(event) => {
                              let { name, value } = event.target
                              handleChange({
                                target: {
                                  name: name,
                                  value: value.replace(/^[\u25C6\u2756\s?]+|[\u25C6\u2756\s?]+$/g, "").trim().replace(/\s/g, "")
                                }
                              });
                            }}
                          />
                          {errors.email && (
                            <p className="text-danger">{errors.email}</p>
                          )}
                        </div>
                        <div className="col-md-12 mb-1 d-flex flex-column">
                          <Label htmlFor="csvFile">Number of Tickets </Label>
                          <Input
                            value={values.noOfTickets}
                            type="number"
                            name="noOfTickets"
                            min={1}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          />
                          {errors.noOfTickets && (
                            <p className="text-danger">{errors.noOfTickets}</p>
                          )}
                        </div>
                      </div>}

                    <div className="col-md-12 mb-1">
                      <Label htmlFor="promocode">Department Code</Label>
                      <Input
                        value={promoCode}
                        name="promocode"
                        onChange={(event) => {
                          handlePromoCode(event.target.value);
                        }}
                      />
                      {errors.promoCode && (
                        <p className="text-danger">{errors.promoCode}</p>
                      )}
                    </div>

                    <div className="form-button mt-3 text-center">
                      <button
                        id="submit"
                        type="submit"
                        className="btn btn-lg btn-dark submit mt-3 col-sm-6 col-12"
                        disabled={buttonContent !== "Submit"}
                      >
                        {buttonContent}
                      </button>
                    </div>
                    {errors.fileUpload && (
                      <p className="text-danger">{errors.fileUpload}</p>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default GuestForm;
