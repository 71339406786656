import axios from "axios"
import { Auth } from "aws-amplify";
import {
    SESSIONS_URL,
    EVENTS_URL,
    TICKET_TYPE_URL,
    CSV_UPLOAD_URL,
    INDIVIDUAL_GUEST_URL,
    CSV_UPLOAD_STATUS,
    USER_ACTIVITY_LOG_API_URL,
    CUSTOMER_NOTES
} from "../common/Constants";

const userActivityLog = async (userRequest) => {
    const userInfo = await Auth.currentAuthenticatedUser();
    const userEmail = userInfo.attributes.email;
    const { action, api_request_data } = { ...userRequest }

    const details = {
        app_name: "GUEST LIST FORM",
        action,
        user_email: userEmail,
        api_request_data,
        comments: ""
    }
    return axios.post(USER_ACTIVITY_LOG_API_URL, details)
}

// check csv upload progress
const getCsvUploadProgress = async (jobId) => {
    try {
        const response = await axios.get(`${CSV_UPLOAD_STATUS}/${jobId}`);
        return response.data;
    } catch (error) {
        return [];
    }
}

//getCustomerNotes
const getCustomerNotes = () => {
    return JSON.parse(CUSTOMER_NOTES);
}


//fetch available events
const getEvents = async () => {
    try {
        const response = await axios
            .get(EVENTS_URL);
        return response.data.event_template._data;
    } catch (error) {
        return [];
    }
}

//fetch available sessions of a particular event 
const getSessions = async (event_id) => {
    try {
        const response = await axios
            .get(SESSIONS_URL + `/eventid/${event_id}/sessions`);
        return response.data.event_session._data;
    } catch (error) {
        return [];
    }
}

//fetch ticket types of a session
const getTicketTypes = async (session_id) => {
    try {
        const response = await axios
            .get(TICKET_TYPE_URL + `/sessionid/${session_id}/tickettype`, { params: { _embed: `ticket_group,ticket_type` } });
        var object = Object.assign({}, ...Object.entries({ ...response.data.ticket_group._data }).map(([a, b]) => ({ [b.id]: b.name })));
        return {
            ticketType: response.data.ticket_type._data,
            ticketGroup: object
        };
    } catch (error) {
        return [];
    }
}

const createInduvidualGuestEntry = async (data) => {
    const {
        eventId,
        sessionId,
        ticketTypeId,
        customerNote,
        promoCode,
        firstName,
        lastName,
        email,
        quantity
    } = data;

    userActivityLog({
        action: 'Individual - guest entry creation',
        api_request_data: data
    })

    const formData = new FormData();
    formData.append('eventId', eventId);
    formData.append('sessionId', sessionId);
    formData.append('ticketTypeId', ticketTypeId);
    formData.append('customerNote', customerNote);
    formData.append('promoCode', promoCode);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('quantity', quantity);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    };

    return axios.post(INDIVIDUAL_GUEST_URL, formData, config)
        .then((response) => {
            if (response.data) {
                let rows = [...response.data.errors, ...response.data.success];
                return rows;
            }
            else {
                return []
            }
        })
        .catch((error) => {
            console.log("errors,", error);
            return [];
        });
}

//send CSV to Lambda function
const sendCSVToLambda = async (file, event, session, ticketType, customerNote, promoCode, jobId) => {

    userActivityLog({
        action: 'Spreadsheet upload - guest entry creation',
        api_request_data: { file, event, session, ticketType, customerNote, promoCode, jobId }
    })

    const formData = new FormData();
    formData.append('file', file);
    formData.append('eventId', event);
    formData.append('sessionId', session)
    formData.append('ticketTypeId', ticketType)
    formData.append('promoCode', promoCode)
    formData.append('customerNote', customerNote)
    formData.append('jobId', jobId)
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    };

    return axios
        .post(CSV_UPLOAD_URL, formData, config)
        .then((response) => {
            if (response.data) {
                let rows = [...response.data.errors, ...response.data.success];
                return rows;
            }
            else {
                return []
            }
        })
        .catch((error) => {
            console.log("errors,", error);
            return [];
        });
}

export const service = {
    getCustomerNotes,
    getEvents,
    getSessions,
    getTicketTypes,
    sendCSVToLambda,
    createInduvidualGuestEntry,
    getCsvUploadProgress,
    userActivityLog
}